import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Alert, AlertTitle, Divider} from '@mui/material';
import Signup from './signup';

// Icons

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://pontovinte.com/">
        Pontovinte LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn(props) {

  const [openSignup, setOpenSignup] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [viewPassword, setViewPassword] = React.useState(false);

  const signupCloseCallback = React.useCallback(() => {
    setOpenSignup(false);
  })

  async function handleSubmit(event) {
    event.preventDefault()
 
    const formData = new FormData(event.currentTarget)
    const email_address = formData.get('email_address')
    const password = formData.get('password')
 
    await fetch('/api/users/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email_address, password }),
    }).then((resp) => {if(props?.callback) props.callback()})
  }

  return (
      <Container sx={{ background: '#ffff'}} component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: '#ffff'
          }}
        >
          <Grid container xl>
            <Grid item xs={8}>
              <Typography gutterBottom align='left' variant='h4' fontFamily={'sans-serif'}>Login</Typography>
            </Grid>
            <Grid item xs={4} align={'end'}>
              <Typography fontSize={10} color={'primary'}>manda oferta</Typography>
              <Typography fontSize={8} color={'secondary'}>por: Pontovinte</Typography>
            </Grid>
          </Grid>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email_address"
              label="e-mail"
              name="email_address"
              type='email'
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type={ viewPassword ? "text" : "password"}
              id="password"
              onChange={(evt) => {setPassword(evt.target.value)}}
              InputProps={{endAdornment: viewPassword ? <IconButton onClick={() => setViewPassword(false)}><VisibilityIcon/></IconButton> : <IconButton onClick={() => setViewPassword(true)}><VisibilityOffIcon/></IconButton> }}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              color='primary'
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: 0 }}
            >
              Entrar
            </Button>
            <Divider sx={{ paddingTop: 1, paddingBottom: 1}} />
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='h6'>Ainda não possui cadastro?</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant='outlined'
                  sx={{ borderRadius: 0}}
                  onClick={() => setOpenSignup(true)}>
                  Cadastrar-se
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <Signup closeCallback={signupCloseCallback} open={openSignup}/>
      </Container>
  );
}
