import * as React from 'react';
import { Dialog } from '@mui/material';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

// Icons

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://pontovinte.com/">
          Pontovinte LLC
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

export default function Signup(props) {
    const { open, closeCallback } = props;

    const [password, setPassword] = React.useState('');
    const [passwordConfirm, setPasswordConfirm] = React.useState('');
    const [enableButton, setEnableButton] = React.useState(true);
    const [viewPassword, setViewPassword] = React.useState(false);
  
    React.useEffect(() => {
      if (password && passwordConfirm) {
        setEnableButton(doesPasswordMatches())
      } else {
        setEnableButton(true)
      }
      
    }, [password, passwordConfirm])

    const doesPasswordMatches = () => {
        return password !== passwordConfirm;
    }

    async function handleSubmit(event) {
      event.preventDefault()
   
      const formData = new FormData(event.currentTarget)
      const email_address = formData.get('email_address')
      const password = formData.get('password')
   
      const response = await fetch('/api/users', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email_address, password }),
      })
   
      if (response.ok) {
        if (props?.closeCallback) props?.closeCallback();
      } else {
        // Handle errors
      }
    }
    
    return (
        <React.Fragment>
            <Dialog 
                fullScreen
                open={open}
                onClose={closeCallback}
                TransitionComponent={Transition}>
            <Container sx={{ background: '#ffff'}} component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: '#ffff'
          }}
        >
          <Grid container xl>
            <Grid item xs={12}>
                <IconButton onClick={closeCallback}>
                    <ArrowBackIcon/>
                </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Typography gutterBottom align='left' sx={{ marginTop: 3}} variant='h4' fontFamily={'sans-serif'}>Cadastre-se</Typography>
            </Grid>
            <Grid item xs={4} align={'end'}>
              <Typography fontSize={18} color={'primary'}>manda oferta</Typography>
              <Typography fontSize={10} color={'secondary'}>por: Pontovinte</Typography>
            </Grid>
          </Grid>
          <Typography>
            Preencha os campos abaixo para criar uma nova conta.
          </Typography>
          <br/>
          <Typography>
            Após o preenchimento, enviaremos um link de validação para o e-mail informado
          </Typography>
          <Box component="form" method='POST' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email_address"
              label="e-mail"
              name="email_address"
              type='email'
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              error={doesPasswordMatches()}
              required
              fullWidth
              name="password"
              label="Senha"
              type={ viewPassword ? "text" : "password"}
              id="password"
              onChange={(evt) => {setPassword(evt.target.value)}}
              InputProps={{endAdornment: viewPassword ? <IconButton onClick={() => setViewPassword(false)}><VisibilityIcon/></IconButton> : <IconButton onClick={() => setViewPassword(true)}><VisibilityOffIcon/></IconButton> }}
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              error={doesPasswordMatches()}
              required
              fullWidth
              name="password_confirmation"
              label="Confirmar senha"
              type={ viewPassword ? "text" : "password"}
              id="password-confirmation"
              autoComplete="current-password"
              InputProps={{endAdornment: viewPassword ? <IconButton onClick={() => setViewPassword(false)}><VisibilityIcon/></IconButton> : <IconButton onClick={() => setViewPassword(true)}><VisibilityOffIcon/></IconButton> }}
              onChange={(evt) => {setPasswordConfirm(evt.target.value)}}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={enableButton}
              sx={{ mt: 3, mb: 2, borderRadius: 0 }}
            >
              Cadastrar-se
            </Button>
            <Link href='https://wiki.pontovinte.com' underline='always' fontSize={14}>Termos de uso</Link>
            <br/>
            <Link href='https://wiki.pontovinte.com' underline='always' fontSize={14}>Políticas de privacidade</Link>
            <FormGroup sx={{ marginTop: 2}}>
              <FormControlLabel control={<Checkbox />} label="Li e aceito os termos de uso es as políticas de privacidade" />
            </FormGroup>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      </Dialog>
        </React.Fragment>
    )
}