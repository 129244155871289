
import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Alert, Divider, Stack } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CupomDialog(props) {

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Cupom da oferta
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
            <Stack sx={{ width: '100%'}} spacing={2}>
                <Alert severity='info'>Informe os seguintes códigos no momento da compra para reivindicar a sua oferta</Alert>
            </Stack>
          <Divider variant='middle' sx={{ margin: 2 }}/>
          <Typography gutterBottom>
            Identificador do cliente:
          </Typography>
          <Typography fontSize={33} color={'primary'}><b>{props.requestKey}</b></Typography>
          <Typography gutterBottom>
            Identificador da oferta:
          </Typography>
          <Typography fontSize={33} color={'primary'}><b>{props.offerKey}</b></Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}