
import { Alert, Backdrop, Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as React from 'react';

//LOCALES
import 'dayjs/locale/pt-br';

export default function RequestForm(props) {

	const [ brand, setBrand ] = React.useState('');
	const [ productDesc, setProductDesc ] = React.useState('');
	const [ linkRef, setLinkRef ] = React.useState('');
	const [ quantity, setQuantity ] = React.useState(1);
	const [ validUntil, setValidUntil ] = React.useState(0);

	const [ displayBackdrop, setDisplayBackDrop ] = React.useState(false);
	const [ displayErrorAlert, setDisplayErrorAlert ] = React.useState(false);

    React.useEffect(() => {
      setBrand('');
      setProductDesc('');
      setLinkRef('');
      setQuantity(1);
      setValidUntil(0);
    }, [])

	const handleValidUntilDateSelect = (evt) => {
		if (evt) {
			setValidUntil(Math.floor(new Date(evt).getTime() / 1000));
		}
	}

	const publishOfferRequest = async (requestBody) => {
		setDisplayErrorAlert(!hasValidPayload());
		if (hasValidPayload()) {
			setDisplayBackDrop(true);
			await fetch("/api/mandaoferta/request", {
				method: 'POST',
				body: JSON.stringify(requestBody),
				headers: {
					'Content-Type': 'application/json',
				},
			}).then((res) => {
        setDisplayBackDrop(false);
        if (props.cancel) props.success();
      }).catch(e => console.error(e));
		}
	}

	const buildRequestBody = () => {
		let body = {
			brand: brand,
			quantity: quantity,
			product_description: productDesc,
			product_link_ref: linkRef,
			valid_until: validUntil
		}
		return body;
	}

	const hasValidPayload = () => {
		if (brand.trim().length === 0) return false;
		if (quantity === 0) return false;
		if (productDesc.trim().length === 0) return false;
		if (validUntil === 0) return false;
		return true;
	}

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: '#ffff',
          }}
        >
            <TextField
                label="Nome do produto"
                variant='standard'
                placeholder='Ex: Headset com cancelamento de ruído'
                multiline
                required
				onChange={(evt) => setProductDesc(evt.target.value)}
                fullWidth
				
            />
            <TextField
                label="Marca"
                variant='standard'
                placeholder='Ex: Bose'
				sx={{ marginTop: 2 }}
                required
				onChange={(evt) => setBrand(evt.target.value)}
                fullWidth
            />
            <TextField
                label="Quantidade"
                variant='standard'
                placeholder='Ex: 10'
				sx={{ marginTop: 2 }}
                required
                type='number'
				defaultValue={1}
				onChange={(evt) => setQuantity(Number(evt.target.value))}
                fullWidth
            />
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker disablePast slotProps={{ textField: { label: 'Receber ofertas até a data' , variant: 'standard', fullWidth: true}}} closeOnSelect onChange={(evt) => handleValidUntilDateSelect(evt)} />
            </LocalizationProvider>
            <TextField
                label="Link de referência (opcional)"
                variant='standard'
				sx={{ marginTop: 2 }}
                placeholder='Achou o produto na internet? Cole o link aqui.'
                multiline
				onChange={(evt) => setLinkRef(evt.target.value)}
                fullWidth
            />
            <Button variant='contained' size='large' fullWidth sx={{ marginTop: 2, borderRadius: 0 }} onClick={() => publishOfferRequest(buildRequestBody())}><Typography>Enviar</Typography></Button>
			<Button variant='outlined' size='large' fullWidth sx={{ marginTop: 1, borderRadius: 0 }} onClick={() => props?.cancel()}><Typography>Cancelar</Typography></Button>
			<Stack sx={{ width: '100%' }} spacing={2}>
		  		{ displayErrorAlert ? <Alert severity='error'>Por favor, preencha os campos obrigatórios</Alert> : null }
			</Stack>
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={displayBackdrop}><CircularProgress /></Backdrop>
        </Box>
    )
}