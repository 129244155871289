
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Backdrop, Card, CardContent, CardHeader, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Skeleton, Stack } from '@mui/material';

//ICONS
import GavelIcon from '@mui/icons-material/Gavel';
import { Directions, Instagram } from '@mui/icons-material';
import StorefrontIcon from '@mui/icons-material/Storefront';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProposalDialog(props) {
  const [ offers, setOffers ] = React.useState([]);
  const [ displaySkeleton, setDisplaySkeleton ] = React.useState(false);
  const [ displayConfirmDialog, setDisplayConfirmDialog ] = React.useState(false);
  const [ displayBackDrop, setDisplayBackdrop ] = React.useState(false);

  const [ selectedOfferKey, setSelectedOfferKey ] = React.useState('');

  React.useEffect(() => {
    setOffers([]);
    setDisplaySkeleton(true);
    if (props.requestKey) {
      fetch(`/api/mandaoferta/offers/${props.requestKey}`)
      .then((res) => res.json()
      .then((data) => {
        setDisplaySkeleton(false);
          setOffers(data.offers);
      }))
    }
  }, [props.requestKey])

  const handleOfferAccept = (offerKey) => {
    setSelectedOfferKey(offerKey);
    setDisplayConfirmDialog(true);
  }

  const confirmDialogCallback = React.useCallback(() => {
    acceptOffer(selectedOfferKey);
    setDisplayConfirmDialog(false);
  })

  const cancelDialogCallback = React.useCallback(() => {
    setSelectedOfferKey('');
    setDisplayConfirmDialog(false);
  })

  const acceptOffer = async (offerKey) => {
    setDisplayBackdrop(true);
    await fetch(`/api/mandaoferta/request/${props.requestKey}/offer/${offerKey}/accept`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      setDisplayBackdrop(false);
      props.handleClose();
    })
  }

  const openInstagramWindow = (profile) => {

  }

  const buildListItems = () => {
    let items = [];
    if (offers && offers.length > 0) {
      offers.forEach((offer) => {
        items.push(
          <Card key={offer._key} elevation={0} sx={{ textAlign: 'initial'}} >
            <CardHeader
              avatar={
                <StorefrontIcon fontSize='large'/>
              }
              title={offer.store_details.name}
              subheader={`${offer.store_details.full_address}, ${offer.store_details.city}, ${offer.store_details.state}`}
              action={
                <IconButton>
                  <Directions fontSize='large' color='primary'/>
                </IconButton>
              }
              />
              <CardContent>
              <Stack direction={'column'} textAlign={'initial'}>
                <Typography color={'primary'} fontSize={33}><b>R$ {offer.full_price_offer.toLocaleString('pt-BR')}</b></Typography>
                <Typography color={'primary'} fontSize={13}>ou {offer.installments}x de <b>R$ {offer.installment_price_offer.toLocaleString('pt-BR')}</b></Typography>
                <Typography><b>CNPJ:</b> {offer.store_details.registry}</Typography>
                <Typography><b>Proposta válida até:</b> {new Date(offer.valid_until * 1000).toLocaleDateString('pt-BR')}</Typography>
                <Button sx={{ marginTop: 2, borderRadius: 0 }} fullWidth variant='outlined' size='large' startIcon={<Instagram fontSize='large'/>} onClick={() => openInstagramWindow(offer.store_details.instagram)}>
                        <Typography textTransform={'lowercase'}><b>{offer.store_details.instagram}</b></Typography>
                </Button>
                <Button sx={{ marginTop: 1, borderRadius: 0 }} size={'large'} startIcon={<GavelIcon />} variant='contained' onClick={() => handleOfferAccept(offer._key)}>Aceitar</Button>
              </Stack>
              </CardContent>
              <Divider variant='middle'/>
          </Card>
        )
      })
    }
    return items;
  }

  return (
      <React.Fragment>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Propostas recebidas
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          { displaySkeleton ? <Stack spacing={1} marginLeft={2} marginRight={2}>
            <Skeleton variant="circular" width={60} height={60} />
            <Skeleton variant="text" sx={{ fontSize: '2rem'}} />
            <Skeleton variant="text" sx={{ fontSize: '1rem'}} />
            <Skeleton variant="text" sx={{ fontSize: '1rem'}} />
            <Skeleton variant="text" sx={{ fontSize: '1rem'}} />
            <Skeleton variant="rectangular" height={50}/>
            <Skeleton variant="rectangular" height={50}/>
          </Stack> : null }
          { buildListItems() }
        </List>
      </Dialog>
      <Dialog
        open={displayConfirmDialog}
        >
          <DialogTitle>
            Confirmar
          </DialogTitle>
          <DialogContent>
            <Typography>Você deseja aceitar a oferta selecionada?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDialogCallback}>Cancelar</Button>
            <Button onClick={confirmDialogCallback}>Confirmar</Button>
          </DialogActions>
      </Dialog>
      <Backdrop open={displayBackDrop} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </React.Fragment>
  );
}
