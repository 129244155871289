
import { Avatar, Button, Card, Chip, Divider, Grid, Typography, Stack, ListItem, ListItemText, IconButton, CardHeader } from '@mui/material';
import * as React from 'react';

//ICONS
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DeleteIcon from '@mui/icons-material/Delete';
import ProposalDialog from './proposals';
import CupomDialog from './cupomdialog';
import { Directions } from '@mui/icons-material';
import StorefrontIcon from '@mui/icons-material/Storefront';

export default function AcceptedOfferCards() {

    const [ openCupomDialog, setOpenCupomDialog ] = React.useState(false);
    const [ displaySkeleton, setDisplaySkeleton ] = React.useState(false);
    const [ requests, setRequests ] = React.useState([]);

    const [ selectedRequestKey, setSelectedRequestKey ] = React.useState('');
    const [ selectedOfferKey, setSelectedOfferKey ] = React.useState('');

    React.useEffect(() => {
        refreshRequests();
    }, [])

    const handleOpenCupomDialog = (requestKey, offerKey) => {
        setSelectedRequestKey(requestKey);
        setSelectedOfferKey(offerKey);
        setOpenCupomDialog(true);
    }

    const refreshRequests = () => {
        setRequests([]);
        getRequests().then((data) => setRequests(data.accepted_offers));
    }

    const closeCupomDialogCallback = React.useCallback(() => {
        setSelectedRequestKey('');
        setSelectedOfferKey('');
        setOpenCupomDialog(false);
    })

    async function getRequests() {
        setDisplaySkeleton(true);
        const res = await fetch('/api/mandaoferta/offer/accepted').catch((e) => console.error(e));
        if (!res.ok) {
            throw new Error('Failed to fetch accepted offer requests');
        }
        setDisplaySkeleton(false);
        return res.json();
    }

    const buildCards = () => {
        let cards = [];
        if (requests) {
            requests.forEach((req) => (
                cards.push(
                    <Grid key={req.offer_request._key} item xs={12}>
                        <Card key={req.offer_request._key} square elevation={0} sx={{ marginTop: 2, marginLeft: 2, marginRight: 2, textAlign: 'initial' }}>
                        <CardHeader
                            // onClick={() => setOpenStoreProfileDialog(true)}
                            avatar={
                                // <Avatar sx={{ width: 56, height: 56 }} variant='circular' key={req.offer_request._key} src='/ponto_logo.png'></Avatar>
                                <StorefrontIcon fontSize='large'/>
                              }
                            title={req.offers.store_details.store_name}
                            subheader={`${req.offers.store_details.full_address}, ${req.offers.store_details.city}, ${req.offers.store_details.state}`}
                            action={
                                <IconButton>
                                    <Directions fontSize='large' color='primary'/>
                                </IconButton>
                            }
                            />
                            <Typography><b>Produto:</b> {req.offer_request.product_description}</Typography>
                            <Typography><b>Marca:</b> {req.offer_request.brand}</Typography>
                            <Typography><b>Quantidade:</b> {req.offer_request.quantity}</Typography>
                            <Typography><b>Requisitado em:</b> {new Date(req.offer_request.created_at * 1000).toLocaleDateString('pt-BR')}</Typography>
                            <Typography><b>Oferta válida até:</b> {new Date(req.offer_request.valid_until * 1000).toLocaleDateString('pt-BR')}</Typography>
                            <Typography color={'primary'} fontSize={34}><b>R$ {req.offers.full_price_offer.toLocaleString('pt-BR')}</b></Typography>
                            <Typography color={'primary'} fontSize={13}>ou {req.offers.installments}x de <b>R$ {req.offers.installment_price_offer.toLocaleString('pt-BR')}</b></Typography>
                            
                            <Button fullWidth variant='contained' size='large' endIcon={<LocalOfferIcon sx={{ alignSelf: 'right'}}/>} sx={{ borderRadius: 0, marginTop: 2 }} onClick={() => handleOpenCupomDialog(req.offer_request._key, req.offers._key)}>Ver cupom da oferta </Button>
                            {/* <Button fullWidth color='secondary' variant='contained' endIcon={<DeleteIcon sx={{ align: 'right'}}/>} sx={{ borderRadius: 0, marginTop: 1 }}>Remover</Button> */}
                        </Card>
                        <Divider variant='middle' sx={{ marginTop: 2 }}/>
                    </Grid>
                )
            ))
        }
        
        return cards;
    }

    return (
        <Grid container>
            {buildCards()}
            <CupomDialog open={openCupomDialog} handleClose={closeCupomDialogCallback} requestKey={selectedRequestKey} offerKey={selectedOfferKey}/>
        </Grid>
    )
}