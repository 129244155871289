
import { Alert, Box, Button, Container, CssBaseline, Divider, Grid, Snackbar, Tab, Tabs, Typography } from '@mui/material';
import * as React from 'react';
import RequestsAppBar from './appbar';
import PropTypes from 'prop-types';

import RequestsCards from './requestscards';
import AcceptedOfferCards from './acceptedoffer';
import RequestFormDialog from './requestdialog';

//ICONS
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

export default function ProductRequests() {

    const [ selectedTab, setSelectedTab] = React.useState(0);
    const [ openRequestFormDialog, setOpenRequestFormDialog ] = React.useState(false);

    const [ displayRequestAddedSuccessAlert, setDisplayRequestAddedSuccessAlert ] = React.useState(false);
    const [ displayNoRequestsAlert, setDisplayNoRequestsAlert ] = React.useState(false);

    const handleTabChange = (event, newTabValue) => {
        setSelectedTab(newTabValue);
    }

    const closeRequestDialogCallback = React.useCallback(() => {
        setOpenRequestFormDialog(false);
    })

    const handleReqSuccessCallback = React.useCallback(() => {
        setDisplayRequestAddedSuccessAlert(true);
        setOpenRequestFormDialog(false);
    })

    const closeRequestAddedSuccessAlert = () => {
        setDisplayRequestAddedSuccessAlert(false);
    }

    return (
        <div>
            <RequestsAppBar />
            <Container sx={{ background: '#ffff', marginTop: 1}} component="main" maxWidth="xl">
                <CssBaseline />
                <Grid container xl spacing={1}>
                    <Grid item xs={12}>
                        <Alert severity='info' sx={{ borderRadius: 0 }}><Typography>Clique no botão abaixo para adicionar um novo pedido de oferta</Typography></Alert>
                        <Button fullWidth variant='contained' sx={{ borderRadius: 0, marginTop: 2 }} onClick={() => setOpenRequestFormDialog(true)} endIcon={<LocalOfferIcon />}><Typography>Adicionar pedido</Typography></Button>
                        <RequestFormDialog open={openRequestFormDialog} requestCancelCallback={closeRequestDialogCallback} requestSuccessCallback={handleReqSuccessCallback}/>
                        <Divider sx={{ marginTop: 2 }}></Divider>
                    </Grid>
                    </Grid>
                    <Box sx={{ width: '100%', marginTop: 2 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="request table">
                                <Tab label={'Seus pedidos'} />
                                <Tab label={'Ofertas aceitas'}/>
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={selectedTab} index={0}>
                            <Grid item xs={12}>
                            </Grid>
                                <RequestsCards noRequestsCallback={() => setDisplayNoRequestsAlert(true)}/>
                                </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={1}>
                                <AcceptedOfferCards />
                        </CustomTabPanel>
                        <Snackbar autoHideDuration={12000} open={displayRequestAddedSuccessAlert} onClose={closeRequestAddedSuccessAlert}>
                            <Alert sx={{ borderRadius: 0 }}>
                                <Typography>Seu pedido foi enviado com sucesso, por favor, aguarde as ofertas das lojas participantes.</Typography>
                            </Alert>
                        </Snackbar>
                    </Box>
            </Container>
        </div>
    )
}