import logo from './logo.svg';
import './App.css';
import React from 'react';
import SignIn from './login';
import { ThemeContext } from '@emotion/react';
import { Alert, AlertTitle, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Link, Snackbar, Stack, ThemeProvider, Typography } from '@mui/material';
import { pontovinteTheme, verifyIfLogged, verifyIfValidated } from './utils/Utils';
import ProductRequests from './requests';

function App() {

  const [ isLogged, setIsLogged ] = React.useState(false);
  const [ displayBackDrop, setDisplayBackDrop ] = React.useState(true);
  const [ notValidated, setNotValidated ] = React.useState(false);

  React.useEffect(() => {
    setDisplayBackDrop(true);
    verifyIfLogged(isLoggedCallback, notLoggedCallback);
  }, [])

  const loginCallback = React.useCallback(() => {
    verifyIfLogged(isLoggedCallback, notLoggedCallback);
    reloadPage();
  })

  const isLoggedCallback = React.useCallback(() => {
    setIsLogged(true);
    setDisplayBackDrop(false);
    verifyIfValidated(isValidatedCallback, notValidatedCallback)
  })

  const notLoggedCallback = React.useCallback(() => {
    setIsLogged(false);
    setDisplayBackDrop(false);
  })

  const isValidatedCallback = React.useCallback(() => {
    console.log("User validated")
    setNotValidated(false);
  })

  const notValidatedCallback = React.useCallback(() => {
    console.log("User not validated")
    setNotValidated(true);
  })

  function reloadPage() {
    window.location.reload();
  }

  return (
    <div className="App">
      <ThemeProvider theme={pontovinteTheme}>
        <ProductRequests />
        { !displayBackDrop ? <Dialog
          open={!isLogged}>
            <DialogContent>
              <SignIn callback={loginCallback} />
            </DialogContent>
        </Dialog> : null }
        <Dialog open={notValidated}>
          <DialogContent>
            <Alert severity='warning'>
              <AlertTitle>Quase lá!</AlertTitle>
              <Typography>Ainda não validou o e-mail informado?
                Por favor, acesse a caixa de entrada do e-mail informado e siga as instruções de ativação da conta.</Typography>
              <Divider variant='middle'/>
              <Typography>Não consegue localizar o e-mail?</Typography>
              <Link href="#">Clique aqui para reenviar o e-mail de ativação</Link>
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' href='/logout'>Sair</Button>
          </DialogActions>
        </Dialog>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={displayBackDrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </ThemeProvider>
    </div>
  );
}

export default App;
