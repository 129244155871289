import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import RequestForm from './requestform';

export default function RequestFormDialog(props) {

    React.useEffect(() => {

    }, [props])

    return (
        <Dialog open={props?.open} fullScreen>
            <DialogTitle><Typography>O que você está querendo comprar?</Typography></DialogTitle>
            <DialogContent>
                <RequestForm cancel={props?.requestCancelCallback} success={props?.requestSuccessCallback}/>
            </DialogContent>
        </Dialog>
    )
}
