import { createTheme } from '@mui/material/styles';

export const pontovinteTheme = createTheme({
    palette: {
      primary: {
        main: '#0c4160',
      },
      secondary:{
        main: '#c3ceda',
      },
    },
  });


  export const verifyIfLogged = async (isLoggedCallback, notLoggedCallback) => {
    await fetch("/islogged", {
      method: "GET",
      headers: { 'Content-Type': 'application/json' }
    }).then((res) => { 
      if (res.ok && isLoggedCallback) {
        isLoggedCallback();
      }
      if (!res.ok && notLoggedCallback) {
        notLoggedCallback();
      }
    }).catch((e) => {if (notLoggedCallback) notLoggedCallback()})
  }

  export const verifyIfValidated = async (isValidatedCallback, notValidatedCallback) => {
    await fetch("/api/users/isvalidated", {
      method: "GET",
      headers: { 'Content-Type': 'application/json' }
    }).then((res) => { 
      if (res.ok && isValidatedCallback) {
        isValidatedCallback();
      }
      if (!res.ok && notValidatedCallback) {
        notValidatedCallback();
      }
    }).catch((e) => {if (notValidatedCallback) notValidatedCallback()})
  }