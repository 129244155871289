
import { Alert, Badge, Button, Card, Divider, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import * as React from 'react';

//ICONS
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DeleteIcon from '@mui/icons-material/Delete';
import ProposalDialog from './proposals';
import { RefreshOutlined } from '@mui/icons-material';
import { verifyIfLogged } from '../utils/Utils';

export default function RequestsCards(props) {

    const [ openProposalsDialog, setOpenProposalsDialog ] = React.useState(false);
    const [ requests, setRequests ] = React.useState([]);
    const [ selectedReqKey, setSelectedReqKey ] = React.useState('');

    const [ displaySkeleton, setDisplaySkeleton ] = React.useState(false);

    const closeProposalsDialogCallback = React.useCallback(() => {
        setOpenProposalsDialog(false);
        verifyIfLogged(isLoggedCallback, notLoggedCallback);
    })

    React.useEffect(() => {
        verifyIfLogged(isLoggedCallback, notLoggedCallback);
    }, [])

    const isLoggedCallback = React.useCallback(() => {
        setDisplaySkeleton(false);
        refreshRequests();
      })
    
    const notLoggedCallback = React.useCallback(() => {
        setDisplaySkeleton(false);
    })


    const refreshRequests = () => {
        setRequests([]);
        getRequests().then((data) => setRequests(data.offer_requests)).then((ok) => { if (!ok) {props?.noRequestsCallback()}}).catch((e) => props?.noRequestsCallback());
    }

    const handleOpenProposalsClick = (requestKey) => {
        setSelectedReqKey(requestKey);
        setOpenProposalsDialog(true);
    }

    async function getRequests() {
        setDisplaySkeleton(true);
        const res = await fetch('/api/mandaoferta/customer/logged/requests').catch((e) => console.error(e));
        if (!res.ok) {
            throw new Error('Failed to fetch requests');
        }
        setDisplaySkeleton(false);
        return res.json();
    }

    const buildCards = () => {
        let cards = [];
        if (requests) {
            requests.forEach((req) => (
                cards.push(
                    // <Grid key={req._key} item xs={12} alignItems={'left'}>
                        <Card key={req._key} square elevation={0} sx={{ marginTop: 2, marginLeft: 2, marginRight: 2, textJustify: 'initial', justifyContent: 'initial', justifyItems: 'initial', textAlign: 'initial'}}>
                            <Typography><b>Produto:</b> {req.product_description}</Typography>
                            <Typography><b>Marca:</b> {req.brand}</Typography>
                            <Typography><b>Quantidade:</b> {req.quantity}</Typography>
                            <Typography><b>Requisitado em:</b> {new Date(req.created_at * 1000).toLocaleDateString('pt-BR')}</Typography>
                            <Typography><b>Válido até:</b> {new Date(req.valid_until * 1000).toLocaleDateString('pt-BR')}</Typography>
                            {/* <Typography><b>Local:</b> {req.offer_request_location.city}, {req.offer_request_location.state}</Typography> */}
                            { req.product_link_ref ? <Typography component={'a'} href={req.product_link_ref}><b>Link de referência: </b> {req.product_link_ref} </Typography> : null }
                            { req.offers_count ? <Typography color={'primary'} fontSize={22}><b>Ofertas recebidas: </b> {req.offers_count}</Typography> : null }
                            <Button size='large' disabled={req.offers_count === undefined} fullWidth variant='contained' sx={{  marginTop: 2, borderRadius: 0 }} endIcon={<Badge sx={{ alignSelf: 'end' }} badgeContent={req.offers_count} ><LocalOfferIcon sx={{ alignSelf: 'right'}}/></Badge>} onClick={() => handleOpenProposalsClick(req._key)}>Ver ofertas recebidas</Button>
                            {/* <Button fullWidth color='secondary' variant='contained' endIcon={<DeleteIcon sx={{ align: 'right'}}/>} sx={{ marginTop: 2, borderRadius: 0, marginTop: 1 }}>Remover</Button> */}
                            <Divider variant='middle' sx={{ marginTop: 2}} />
                        </Card>
                        // <Divider variant='middle' sx={{ marginTop: 2}} />
                    // </Grid>
                )
            ))
        }
        return cards;
    }

    return (
            <Stack direction={'column'} justifyContent={'left'} justifyItems={'left'}>
                <IconButton size='large' sx={{ marginTop: 2 }} onClick={() => refreshRequests()}><RefreshOutlined fontSize='large' /></IconButton>
                { displaySkeleton ?
                <Stack spacing={1}>
                    <Skeleton variant='rectangular' width={300} height={250}/>
                    <Skeleton variant='rectangular' width={300} height={50}/>
                    <Skeleton variant='rectangular' width={300} height={50}/>
                </Stack>
                 : null }
                    {buildCards()}
                 {/* <Button sx={{ marginTop: 2, borderRadius: 0 }} variant='outlined'>Carregar mais</Button> */}
                 {selectedReqKey ? <ProposalDialog requestKey={selectedReqKey} open={openProposalsDialog} handleClose={closeProposalsDialogCallback}/> : null }
            </Stack>
    )
}